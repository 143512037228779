import moment from 'moment-timezone';
import VueCookies from 'vue-cookies';

export default {
  mybid_form: {
    mem_num: VueCookies.get('usernum'),
    closedmemo: 0,
    date_type: 'closedt',
    startdate: moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    enddate: '',
    startprice: '',
    endprice: '',
    search_type: 0,
    search: '',
  },
  bidsearch_form: {
    date_type: 'writedt',
    startdate: moment()
      .tz('Asia/Seoul')
      .subtract(7, 'day')
      .format('YYYY-MM-DD'),
    enddate: moment()
      .tz('Asia/Seoul')
      .format('YYYY-MM-DD'),
    date_term: -1,
    bidtype: 'con',
    itemcode: -1,
    location: -1,
    price_type: 'presum',
    startprice: '',
    endprice: '',
    search_type: 'notinum',
    search: '',
    kind: '',
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 20,
    totalElements: 0,
    pages: 1,
  },
  succCnt: 0,
};
