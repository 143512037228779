import Vue from 'vue';
import moment from 'moment';
Vue.filter('f_progress', function(value) {
  let result = '';
  switch (value) {
    case 'right':
    case 'progress':
    case 'progress2':
      return '진행';
    case 'stop':
      return '정지';
    case 'close':
      return '만료';
    case 'fullout':
    case 'mid_cancel':
    case 'final_canc':
      return '해지';
    case 'etc':
      return '기타';
  }
  return result;
});
Vue.filter('f_bidtype', function(value) {
  switch (value) {
    case 'con':
      return '공사';
    case 'ser':
      return '용역';
    case 'pur':
      return '구매';
  }
});
Vue.filter('f_use_local', function(value) {
  switch (value) {
    case 'Y':
      return '지역+관내';
    case 'N':
      return '사용안함';
    case 'C':
      return '관내만';
  }
});
Vue.filter('f_location', function(loc) {
  const location = {
    0: '전국',
    1: '서울',
    2: '부산',
    3: '광주',
    4: '대전',
    5: '인천',
    6: '대구',
    7: '울산',
    8: '경기',
    9: '강원',
    10: '충북',
    11: '충남',
    12: '경북',
    13: '경남',
    14: '전북',
    15: '전남',
    16: '제주',
    17: '세종',
  };
  let result = [];
  if (Array.isArray(loc)) {
    for (let j in loc) {
      let sub_result = [];
      for (let k in location) {
        if ((loc[j] & Math.pow(2, parseInt(k))) > 0) sub_result.push(location[k]);
      }
      result.push(sub_result.join(','));
    }
    return result.join('<br>');
  } else {
    for (let k in location) {
      if ((loc & Math.pow(2, parseInt(k))) > 0) result.push(location[k]);
    }
    return result.join(',');
  }
});
Vue.filter('f_number_format', (number) => {
  if (number) return new Intl.NumberFormat().format(number);
  else return '';
});
Vue.filter('f_pay_stat', function(value) {
  switch (value) {
    case 'perfect':
      return '완납';
    case 'partial':
      return '분납';
    case 'unpaid':
      return '미납';
  }
});
Vue.filter('f_tuchalproc_label', function(value) {
  switch (value) {
    case '01':
      return '1순위';
    case '02':
      return '투찰';
    case '03':
      return '미투찰';
    case '04':
      return '불이행';
    case '05':
      return '최종낙찰';
    case '06':
      return '1순위(불이행)';
    case '07':
      return '불이행(미낙찰)';
    case '08':
      return '미투찰(미낙찰)';
  }
});
Vue.filter('f_kind_label', function(value) {
  switch (value) {
    case 'U':
      return '일반상담';
    case 'N':
      return '신규';
    case 'C':
      return '해지';
    case 'R':
      return '재계약';
    case 'S':
      return '보수상담';
    case 'L':
      return '관심';
    case 'M':
      return '메모';
  }
});
Vue.filter('f_contract', function(value) {
  switch (value) {
    case '10':
      return '일반';
    case '20':
      return '제한';
    case '30':
      return '지명';
    case '40':
      return '수의';
    case '50':
      return '장기';
    case '60':
      return '복수경쟁';
    case '70':
      return '역경매';
    case '80':
      return '실적';
  }
});
Vue.filter('f_yegatype', function(value) {
  switch (value) {
    case '00':
      return '비예가';
    case '11':
      return '단일예가';
    case '17':
      return '복수예가 : 3(추첨예가)/7(총예가)';
    case '20':
      return '복수예가 : 3(추첨예가)/10(총예가)';
    case '23':
      return '복수예가 : 4(추첨예가)/10(총예가)';
    case '25':
      return '복수예가 : 4(추첨예가)/15(총예가)';
    case '27':
      return '복수예가 : 3(추첨예가)/15(총예가)';
    case '28':
      return '복수예가 : 2(추첨예가)/5(총예가)';
    case '29':
      return '복수예가 : 3(추첨예가)/5(총예가)';
    case '30':
      return '복수예가 : 7(추첨예가)/15(총예가)';
    case '31':
      return '복수예가 : 5(추첨예가)/15(총예가)';
    case '32':
      return '복수예가 : 2(추첨예가)/15(총예가)';
    case '33':
      return '복수예가 : 1(추첨예가)/3(총예가)';
    case '90':
      return '공고서참조';
  }
});
Vue.filter('f_from_unixtime', function(value, format) {
  return value ? moment.unix(value).format(format) : '';
});
