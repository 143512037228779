import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';

import { refreshToken } from '../auth.js';
import router from '../router/index.www.js';

Vue.use(VueAxios, axios);

axios.defaults.headers.common = {
  Authorization: `Bearer ${VueCookies.get('token')}`,
  Shopkey: window.siteconfig.name,
};
axios.defaults.baseURL = process.env.VUE_APP_API;

let refreshing = false;

axios.interceptors.response.use(
  (response) => {
    const refreshed = VueCookies.get('refreshed');
    const cur_time = parseInt(new Date().valueOf() / 1000);
    const diff = cur_time - refreshed;

    if (!refreshing && refreshed > 0) {
      if (diff > 60) {
        refreshing = true;

        refreshToken()
          .then(({ status }) => {
            if (status === 200) {
              refreshing = false;
            }
          })
          .catch(() => {
            refreshing = false;
          });
      }
    }

    return response;
  },
  (error) => {
    if (error.response.status == 401 && error.response.data.msg === 'Token has expired') {
      router.push({ name: 'home' });
    }
    return Promise.reject(error);
  }
);

export default new VueAxios({});
