import Vue from 'vue';
import VueCookies from 'vue-cookies';

export default {
  async login(userid, password) {
    var ip_addr = await this.getIp();
    return Vue.axios.post('/api/auth/login', {
      userid,
      password,
      ip_addr,
    });
  },
  async managerLogin(userid, password) {
    var ip_addr = await this.getIp();
    return Vue.axios.post(`/api/auth/managerlogin?shopkey=${window.siteconfig.name}`, {
      userid,
      password,
      ip_addr,
    });
  },
  async managerSmsLogin(userid, senumber, logintype) {
    var ip_addr = await this.getIp();
    return Vue.axios.post('/api/auth/managerlogin', {
      userid,
      senumber,
      logintype,
      ip_addr,
    });
  },
  async autologin(token) {
    return Vue.axios.post('/api/auth/autologin', {
      token,
    });
  },
  async altoken(mem_num) {
    const access_token = VueCookies.get('token');
    Vue.axios.defaults.headers.common = {
      Authorization: `Bearer ${access_token}`,
      Shopkey: window.siteconfig.name,
    };

    return Vue.axios.post('/api/auth/altoken', {
      mem_num,
    });
  },
  async allowAuth() {
    return Vue.axios.get(`/api/auth/allowauth`);
  },
  async getIp() {
    // https://httpbin.org/ip
    // https://test.bidcp.net/x_forwarded_for/
    let shopkey = window.siteconfig.name;
    console.log(shopkey);
    var ip = 'none';
    if (shopkey == 'demo2') {
      await Vue.axios
        .get('https://test.bidcp.net/x_forwarded_for/')
        .then((response) => {
          // ip = response.data.origin;
          console.log('get ip resp [demo2]', response);
          ip = response.data.trim();
        })
        .catch((error) => {
          //console.log(error.response);
        });
    } else {
      await Vue.axios
        .get('https://httpbin.org/ip')
        .then((response) => {
          console.log('get ip resp', response);
          ip = response.data.origin;
        })
        .catch((error) => {
          //console.log(error.response);
        });
    }
    console.log(`ip: [${ip}]`);
    return ip;
  },
  async logout(ltype) {
    ltype = ltype ? ltype : 'admin';
    var ip_addr = await this.getIp();
    var usernum = VueCookies.get('usernum');
    return Vue.axios.post('/api/auth/logout', {
      ltype,
      usernum,
      ip_addr,
    });
  },
};
