import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import { refreshToken } from '../auth.js';
import { RoutesWWW } from './routes.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: RoutesWWW,
});

router.beforeEach(async (to, from, next) => {
  if (VueCookies.get('token') === null && VueCookies.get('refresh_token') !== null) {
    await refreshToken();
  }

  if (to.matched.some((record) => record.meta.unauthorized) || VueCookies.get('token')) {
    return next();
  }

  return next('/client/login');
});

export default router;
