import Vue from 'vue';

export default {
  async getItems(payload) {
    return Vue.axios.get('/api/mng/items/v2/', { params: payload });
  },
  async getProgressMembers(payload) {
    return Vue.axios.get('/api/mng/progress-members/', { params: payload });
  },
};
