import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import bidresult from './modules/bidresult';
import counsel from './modules/counsel';
import marketingCounsel from './modules/marketing-counsel';
import marketingCon from './modules/marketing-con';
import marketingDetail from './modules/marketing-detail';
import marketingPur from './modules/marketing-pur';
import marketing_ser from './modules/marketing-ser';
import NewMarketingCon from './modules/newmarketing-con';
import Newmarketing from './modules/new-marketing';
import NewmarketingDetail from './modules/new-marketing-detail';
import MemMarketingFolderData from './modules/mem-marketing-folder-data';
import powerkeyD2b from './modules/powerkey-d2b';
import powerkeyEtc0 from './modules/powerkey-etc0';
import powerkeyEtc1 from './modules/powerkey-etc1';
import powerkeyG2b from './modules/powerkey-g2b';
import powerkeyKepco from './modules/powerkey-kepco';
import powerkeyLh from './modules/powerkey-lh';
import officeanal from './modules/officeanal';
import bidbox from './modules/bidbox/';
import mng from './modules/mng/';
import sales from './modules/sales';
import memstat from './modules/memstat';
import tuchal from './modules/tuchal';
import admin from './modules/admin';
import adminlog from './modules/adminlog';
import payed from './modules/payed';
import user from './modules/user';
import usernotice from './modules/usernotice';
import daemonlog from './modules/daemon-log';
import smslog from './modules/smslog';
import home from './modules/home';
import counselreq from './modules/counselreq';
import bidsearch from './modules/bidsearch';
import code from './modules/code';
import adminnotice from './modules/adminnotice';
import config from './modules/config';
import noti from './modules/noti';
import assignment from './modules/assignment';
import role from './modules/role';
import roleChild from './modules/role-child';
import task from './modules/task';
import taskChild from './modules/task-child';
import operation from './modules/operation';
import operationChild from './modules/operation-child';
import bidinfo from './modules/bidinfo';
import mainpage from './modules/mainpage';
import myBid from './modules/mybid';
import myResult from './modules/myresult';
import userlog from './modules/userlog';
import v3bidsuccom from './modules/v3bidsuccom';
import commission from './modules/commission';
import office from './modules/office';
import userContract from './modules/user-contract';
import userAnalconfig from './modules/user-analconfig';
import userResult from './modules/user-result';
import userCommission from './modules/user-commission';
import userCounsel from './modules/user-counsel';
import userUpdate from './modules/user-update';
import counselFolder from './modules/counsel-folder';
import counselFolderData from './modules/counsel-folder-data';
import congsuc from './modules/congsuc';
import officeEtc from './modules/office-etc';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth: auth,
    bidresult: bidresult,
    counsel: counsel,
    marketingCounsel: marketingCounsel,
    marketingCon: marketingCon,
    marketingDetail: marketingDetail,
    marketingPur: marketingPur,
    marketing_ser: marketing_ser,
    NewMarketingCon: NewMarketingCon,
    Newmarketing: Newmarketing,
    NewmarketingDetail: NewmarketingDetail,
    MemMarketingFolderData: MemMarketingFolderData,
    officeanal: officeanal,
    sales: sales,
    memstat: memstat,
    admin: admin,
    adminlog: adminlog,
    bidbox: bidbox,
    mng: mng,
    tuchal: tuchal,
    payed: payed,
    powerkeyD2b: powerkeyD2b,
    powerkeyEtc0: powerkeyEtc0,
    powerkeyEtc1: powerkeyEtc1,
    powerkeyG2b: powerkeyG2b,
    powerkeyKepco: powerkeyKepco,
    powerkeyLh: powerkeyLh,
    user: user,
    usernotice: usernotice,
    daemonlog: daemonlog,
    smslog: smslog,
    home: home,
    counselreq: counselreq,
    bidsearch: bidsearch,
    code: code,
    adminnotice: adminnotice,
    config: config,
    noti: noti,
    assignment: assignment,
    role: role,
    roleChild: roleChild,
    task: task,
    taskChild: taskChild,
    operation: operation,
    operationChild: operationChild,
    bidinfo,
    mainpage,
    userlog: userlog,
    v3bidsuccom: v3bidsuccom,
    commission: commission,
    myBid: myBid,
    myResult: myResult,
    office: office,
    userContract: userContract,
    userAnalconfig: userAnalconfig,
    userResult: userResult,
    userCommission: userCommission,
    userCounsel: userCounsel,
    userUpdate: userUpdate,
    counselFolder: counselFolder,
    counselFolderData: counselFolderData,
    congsuc: congsuc,
    officeEtc: officeEtc,
  },
});
