import Vue from 'vue';

export default {
  async getBidboxes(payload) {
    return Vue.axios.get('/api/mng/bidbox/list/', { params: payload });
  },
  async getBidbox(payload) {
    return Vue.axios.get('/api/mng/bidbox/view/', { params: payload });
  },
  async updateAnaldata(payload) {
    return Vue.axios.post('/api/ubid/analdata-update', payload);
  },
  async updateState(payload) {
    return Vue.axios.post('/api/table/analdata/update-state/', payload);
  },
  async updateTeamdocInfo(payload) {
    return Vue.axios.post('/api/table/teamdocinfo/update-memo/', payload);
  },
  async updateAnaldataMngmemo(payload) {
    return Vue.axios.post('/api/table/analdata/update-mngmemo/', payload);
  },
  async copyFromPrev(payload) {
    return Vue.axios.post('/api/table/analdata/copyfromprev/', payload);
  },
  async copyToDelayedFromPrev(payload) {
    return Vue.axios.post('/api/table/analdata/copytodelayedfromprev/', payload);
  },
  async getMemberPopover(payload) {
    return Vue.axios.get('/api/mng/teammember/popover/' + payload);
  },
};
