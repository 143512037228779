import api from './api';
import * as actionTypes from './action-types';
import moment from 'moment';

const M_INIT_SEARCH_PARAMS = 'INIT_SEARCH_PARAMS';
const M_SET_ITEMS = 'SET_ITEMS';
const M_SET_PROGRESS_MEMBERS = 'M_SET_PROGRESS_MEMBERS';

const today = moment().format('YYYY-MM-DD');
const state = {
  items: [],
  progress_members: [],
  search_params: {
    startdate: today,
    enddate: today,
    kind: '',
    search_type: '',
    search_word: '',
    bidproc: '',
    date0: 'closedt',
    bidtype: '',
    convention: '',
    price0: '',
    price1: '',
    price2: '',
    itemcode: -1,
    location: '-1',
    mng_num: 0,
    counseler_num: 0,
    mem_num: 0,
    size: 20,
    sort: 'closedt',
    desc: 'asc',
  },
  loading: false,
};

const getters = {
  items: (state) => state.items,
  loading: (state) => state.loading,
  getSearchParams: (state) => state.search_params,
  getProgressMembers: (state) => state.progress_members,
};

const mutations = {
  [M_INIT_SEARCH_PARAMS]: (state, data) => {
    state.search_params = data ? Object.assign({}, state.search_params, data) : state.search_params;
    state.loading = true;
  },
  [M_SET_ITEMS]: (state, data) => {
    state.items = data;
    state.loading = false;
  },
  [M_SET_PROGRESS_MEMBERS]: (state, data) => {
    state.progress_members = data.items;
  },
};

const actions = {
  [actionTypes.GET_ITEMS]: async ({ commit, getters }, payload) => {
    try {
      commit(M_INIT_SEARCH_PARAMS, payload);
      const { data } = await api.getItems(getters.getSearchParams);

      commit(M_SET_ITEMS, data);
    } catch (e) {
      commit(M_SET_ITEMS, []);
    }
  },
  [actionTypes.GET_PROGRESS_MEMBERS]: async ({ commit, getters }, payload) => {
    try {
      const { data } = await api.getProgressMembers(payload);
      commit(M_SET_PROGRESS_MEMBERS, data);
    } catch (e) {
      commit(M_SET_PROGRESS_MEMBERS, []);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
