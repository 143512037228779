import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  admin_notice: [],
  notice: [],
  recent_join_counsel: [],
  recent_join_counsel_bool: true,
  recent_suc: [],
  recent_suc_bool: true,
  bidresult_auth_bool: false,
  counter: {
    right_user: 0,
    stop_user: 0,
    today_sign: 0,
    today_rank1: 0,
    today_real_rank1: 0,
  },
  loading: false,
};

const getters = {
  admin_notice: (state) => state.admin_notice,
  notice: (state) => state.notice,
  recent_join_counsel: (state) => state.recent_join_counsel,
  recent_join_counsel_bool: (state) => state.recent_join_counsel_bool,
  recent_suc: (state) => state.recent_suc,
  recent_suc_bool: (state) => state.recent_suc_bool,
  bidresult_auth_bool: (state) => state.bidresult_auth_bool,
  counter: (state) => state.counter,
  loading: (state) => state.loading,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.admin_notice = [];
    state.notice = [];
    state.recent_join_counsel = [];
    state.recent_join_counsel_bool = true;
    state.recent_suc = [];
    state.recent_suc_bool = true;
    state.bidresult_auth_bool = false;
    state.counter = {
      right_user: 0,
      stop_user: 0,
      today_sign: 0,
      today_rank1: 0,
      today_real_rank1: 0,
    };
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.admin_notice = response.admin_notice;
    state.notice = response.notice;
    state.recent_join_counsel = response.recent_join_counsel;
    state.recent_join_counsel_bool = response.recent_join_counsel_bool;
    state.recent_suc = response.recent_suc;
    state.recent_suc_bool = response.recent_suc_bool;
    state.bidresult_auth_bool = response.bidresult_auth_bool;
    state.counter = response.counter;
    state.loading = false;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.admin_notice = [];
    state.notice = [];
    state.recent_join_counsel = [];
    state.recent_join_counsel_bool = true;
    state.recent_suc = [];
    state.recent_suc_bool = true;
    state.bidresult_auth_bool = false;
    state.counter = {
      right_user: 0,
      stop_user: 0,
      today_sign: 0,
      today_rank1: 0,
      today_real_rank1: 0,
    };
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      const { data } = await api.fetchHome();

      if (data.hasOwnProperty('error')) {
        commit(mutationTypes.FETCH_FAILURE);
      } else {
        commit(mutationTypes.FETCH_SUCCESS, data);
      }
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
