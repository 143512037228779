// import axios from "axios";
import Vue from 'vue';
import VueCookies from 'vue-cookies';

export async function userlogout() {
  try {
    Vue.axios.defaults.headers.common['Authorization'] = undefined;

    VueCookies.remove('userid');
    VueCookies.remove('token');
    VueCookies.remove('refresh_token');
    VueCookies.remove('name');
  } catch (err) {
    return err;
  }
}

export async function userlogin(userid, password) {
  try {
    let access_token = VueCookies.get('token');
    Vue.axios.defaults.headers.common = {
      Authorization: `Bearer ${access_token}`,
      Shopkey: window.siteconfig.name,
    };

    const token = await Vue.axios.post('/api/auth/login', {
      userid,
      password,
    });
    VueCookies.set('userid', userid, '1d');
    VueCookies.set('token', token.data.token, '60min');
    VueCookies.set('refresh_token', token.data.refresh_token, '30d');
    Vue.axios.defaults.headers['refresh_token'] = VueCookies.get('refresh_token');
    return token;
  } catch (err) {
    return err;
  }
}

export async function refreshToken() {
  try {
    const refresh_token = VueCookies.get('refresh_token');
    const _axios = Vue.axios.create({
      baseURL: process.env.VUE_APP_API + '/api/auth/refresh',
    });
    _axios.defaults.headers.common = {
      Authorization: `Bearer ${refresh_token}`,
      Shopkey: window.siteconfig.name,
    };
    const response = await _axios.post();

    VueCookies.set('token', response.data.token, '60min');
    VueCookies.set('userid', VueCookies.get('userid'), '1d');
    VueCookies.set('usernum', VueCookies.get('usernum'), '1d');
    VueCookies.set('name', VueCookies.get('name'), '30d');
    VueCookies.set('userinfo', VueCookies.get('userinfo'), '30d');
    VueCookies.set('refreshed', parseInt(new Date().valueOf() / 1000), '60min');

    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

    return response;
  } catch (err) {
    console.log(err);
    await userlogout();
    return err;
  }
}

export async function getData() {
  let access_token = VueCookies.get('token');
  Vue.axios.defaults.headers.common = {
    Authorization: `Bearer ${access_token}`,
    Shopkey: window.siteconfig.name,
  };

  await Vue.axios
    .get('/api/auth/data')
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.error('BMS SEARCH ERROR : ' + error);
    });
}
