import api from './api';
import * as actionTypes from './action-types';

const M_INIT = 'INIT';
const M_SET_BIDBOX = 'SET_BIDBOX';
const M_SET_BIDBOXES = 'SET_BIDBOXES';
const M_UPDATE_RESP = 'UPDATE_RESP';
const M_UPDATESTATE_RESP = 'UPDATESTATE_RESP';
const M_UPDATETEAMDOCINFO_RESP = 'UPDATETEAMDOCINFO_RESP';
const M_UPDATE_ERROR = 'UPDATE_ERROR';
const M_UPDATE_MEMBER_POPOVER = 'UPDATE_MEMBER_POPOVER';

const state = {
  bidboxes: [],
  rank: 0,
  loading: false,
  update_resp: {},
  updatestate_resp: {},
  updateteamdocinfo_resp: {},
  bidbox: null,
  member_popover: {},
  error: '',
};

const getters = {
  items: (state) => state.bidboxes,
  loading: (state) => state.loading,
  getUpdateResp: (state) => state.update_resp,
  getUpdateStateResp: (state) => state.updatestate_resp,
  getUpdateTeamdocinfoResp: (state) => state.updateteamdocinfo_resp,
  memberPopover: (state) => state.member_popover,
  bidbox: (state) => state.bidbox,
  error: (state) => state.error,
};

const mutations = {
  [M_INIT]: (state) => {
    //업체 사정률 메모등 수정시 깜박임으로 인해 원복
    //state.bidboxes = [];
    state.loading = true;
  },
  [M_SET_BIDBOXES]: (state, data) => {
    state.bidboxes = data;
    state.loading = false;
  },
  [M_SET_BIDBOX]: (state, data) => {
    state.bidbox = data;
  },
  [M_UPDATE_RESP]: (state, data) => {
    state.update_resp = data;
  },
  [M_UPDATESTATE_RESP]: (state, data) => {
    state.updatestate_resp = data;
  },
  [M_UPDATETEAMDOCINFO_RESP]: (state, data) => {
    state.updateteamdocinfo_resp = data;
  },
  [M_UPDATE_ERROR]: (state, error) => {
    state.error = error;
  },
  [M_UPDATE_MEMBER_POPOVER]: (state, data) => {
    state.member_popover = data;
  },
};

const actions = {
  [actionTypes.GET_BIDBOXES]: async ({ commit }, payload) => {
    try {
      commit(M_INIT);
      const { data } = await api.getBidboxes(payload);
      commit(M_SET_BIDBOXES, data);
    } catch (e) {
      commit(M_SET_BIDBOXES, []);
    }
  },
  [actionTypes.GET_BIDBOX]: async ({ commit }, payload) => {
    try {
      commit(M_SET_BIDBOX, null);
      const { data } = await api.getBidbox(payload);
      commit(M_SET_BIDBOX, data);
    } catch (e) {
      commit(M_SET_BIDBOX, {});
    }
  },
  [actionTypes.UPDATE_ANALDATA]: async ({ commit }, payload) => {
    try {
      commit(M_UPDATE_RESP, {});
      const { data } = await api.updateAnaldata(payload);
      commit(M_UPDATE_RESP, data);
    } catch (e) {
      commit(M_UPDATE_RESP, { error: '' });
    }
  },
  [actionTypes.UPDATE_STATE]: async ({ commit }, payload) => {
    try {
      const { data } = await api.updateState(payload);
      commit(M_UPDATESTATE_RESP, data);
    } catch (e) {
      commit(M_UPDATESTATE_RESP, {});
    }
  },
  [actionTypes.UPDATE_TEAMDOCINFO]: async ({ commit }, payload) => {
    try {
      const { data } = await api.updateTeamdocInfo(payload);
      commit(M_UPDATETEAMDOCINFO_RESP, data);
    } catch (e) {
      commit(M_UPDATETEAMDOCINFO_RESP, {});
    }
  },
  [actionTypes.UPDATE_ANALDATA_MNGMEMO]: async ({ commit }, payload) => {
    try {
      return await api.updateAnaldataMngmemo(payload);
    } catch (e) {
      commit(M_UPDATE_ERROR, e.message);
    }
  },
  [actionTypes.COPY_FROM_PREV]: async ({ commit }, payload) => {
    try {
      return await api.copyFromPrev(payload);
    } catch (e) {
      commit(M_UPDATE_ERROR, e.message);
    }
  },
  [actionTypes.COPY_TO_DELAYED_FROM_PREV]: async ({ commit }, payload) => {
    try {
      return await api.copyToDelayedFromPrev(payload);
    } catch (e) {
      commit(M_UPDATE_ERROR, e.message);
    }
  },
  [actionTypes.GET_MEMBER_POPOVER]: async ({ commit }, payload) => {
    try {
      commit(M_UPDATE_MEMBER_POPOVER, '');
      const { data } = await api.getMemberPopover(payload);
      // //console.log(data);
      commit(M_UPDATE_MEMBER_POPOVER, data);
      // commit(M_SET_BIDBOX, data);
    } catch (e) {
      // //console.log(e);
      commit(M_UPDATE_MEMBER_POPOVER, '');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
