export const ADD_CUSTOM = 'ADD_CUSTOM';
export const ADD_CUSTOM_SUCCESS = 'ADD_CUSTOM_SUCCESS';
export const ADD_CUSTOM_FAILURE = 'ADD_CUSTOM_FAILURE';

export const CREATE = 'CREATE';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAILURE = 'CREATE_FAILURE';

export const FETCH = 'FETCH';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';

export const FETCH_BIDSEARCH = 'FETCH_BIDSEARCH';
export const FETCH_BIDSEARCH_SUCCESS = 'FETCH_BIDSEARCH_SUCCESS';
export const FETCH_BIDSEARCH_FAILURE = 'FETCH_BIDSEARCH_FAILURE';

// export const FETCH_MYRESULT = 'FETCH_MYRESULT';
// export const FETCH_MYRESULT_SUCCESS = 'FETCH_MYRESULT_SUCCESS';
// export const FETCH_MYRESULT_FAILURE = 'FETCH_MYRESULT_FAILURE';

export const CREATE_MEMO = 'CREATE_MEMO';
export const CREATE_MEMO_SUCCESS = 'CREATE_MEMO_SUCCESS';
export const CREATE_MEMO_FAILURE = 'CREATE_MEMO_FAILURE';

export const SHOW_AMOUNT = 'SHOW_AMOUNT';
export const SHOW_AMOUNT_SUCCESS = 'SHOW_AMOUNT_SUCCESS';
export const SHOW_AMOUNT_FAILURE = 'SHOW_AMOUNT_FAILURE';

export const REGIST = 'REGIST';
export const REGIST_SUCCESS = 'REGIST_SUCCESS';
export const REGIST_FAILURE = 'REGIST_FAILURE';

export const TUCHAL = 'TUCHAL';
export const TUCHAL_SUCCESS = 'TUCHAL_SUCCESS';
export const TUCHAL_FAILURE = 'TUCHAL_FAILURE';
