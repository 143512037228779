import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    biddate_term: '0',
    bidtype: 'ser',
    itemcode1: '',
    itemcode2: '',
    itemcode3: '',
    location: '',
    addr: '',
    officeno: '',
    officename: '',
    tel: '',
    prename: '',
    kind: '',
    not_kind: '',
    is_page: true,
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 100,
    totalElements: 0,
    pages: 1,
  },
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
};

const mutations = {
  [mutationTypes.CREATE]: (state) => {
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.CREATE_SUCCESS]: (state, response) => {
    state.form = {};
    state.items = response.data;
    state.loading = false;
  },
  [mutationTypes.CREATE_FAILURE]: (state) => {
    state.form = {};
    state.items = [];
    state.loading = false;
  },
  [mutationTypes.UPDATE]: (state) => {
    state.loading = true;
  },
  [mutationTypes.UPDATE_SUCCESS]: (state) => {
    state.loading = false;
  },
  [mutationTypes.UPDATE_FAILURE]: (state) => {
    state.loading = false;
  },
  [mutationTypes.DELETE]: (state) => {
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.DELETE_SUCCESS]: (state, response) => {
    state.form = {};
    state.items = response.data;
    state.loading = false;
  },
  [mutationTypes.DELETE_FAILURE]: (state) => {
    state.form = {};
    state.items = [];
    state.loading = false;
  },
  [mutationTypes.FETCH]: (state, page) => {
    state.items = [];
    state.loading = true;
    state.pagination.page = page;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.form = {};
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.pagination.totalElements;
    state.pagination.pages = response.pagination.pages;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.form = {};
    state.items = [];
    state.loading = false;
    state.pagination.totalElements = 0;
    state.pagination.pages = 1;
  },
};

const actions = {
  [actionTypes.CREATE]: async ({ commit }, payload) => {
    commit(mutationTypes.CREATE);
    try {
      const { data } = await api.createMemCounsel(payload);
      commit(mutationTypes.CREATE_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.CREATE_FAILURE);
    }
  },
  [actionTypes.UPDATE]: async ({ commit }, payload) => {
    commit(mutationTypes.UPDATE);
    try {
      const { data } = await api.updateMemCounsel(payload);
      commit(mutationTypes.UPDATE_SUCCESS, data);
      return data;
    } catch (e) {
      commit(mutationTypes.UPDATE_FAILURE);
    }
  },
  [actionTypes.DELETE]: async ({ commit }, payload) => {
    commit(mutationTypes.DELETE);
    try {
      const { data } = await api.deleteMemCounsel(payload);
      commit(mutationTypes.DELETE_SUCCESS, data);
      return data;
    } catch (e) {
      commit(mutationTypes.DELETE_FAILURE);
    }
  },
  [actionTypes.FETCH]: async ({ commit }, { officeno, page = 1 }) => {
    commit(mutationTypes.FETCH, page);
    try {
      let form = state.form;
      form.officeno = officeno;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      form.is_page = true;
      const { data } = await api.fetchMemCounsel({
        ...form,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
