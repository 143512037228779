export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const ALTOKEN_INIT = 'ALTOKEN_INIT';

export const CREATE = 'CREATE';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAILURE = 'CREATE_FAILURE';

export const DELETE = 'DELETE';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';

export const FETCH = 'FETCH';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';

export const DORMANT = 'DORMANT';
export const DORMANT_SUCCESS = 'DORMANT_SUCCESS';
export const DORMANT_FAILURE = 'DORMANT_FAILURE';

export const GET = 'GET';
export const GET_SUCCESS = 'GET_SUCCESS';
export const GET_FAILURE = 'GET_FAILURE';

export const UPDATE = 'UPDATE';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'UPDATE_FAILURE';

export const MANAGERS = 'MANAGERS';
export const MANAGERS_SUCCESS = 'MANAGERS_SUCCESS';
export const MANAGERS_FAILURE = 'MANAGERS_FAILURE';

export const MEMBERS = 'MEMBERS';
export const MEMBERS_SUCCESS = 'MEMBERS_SUCCESS';
export const MEMBERS_FAILURE = 'MEMBERS_FAILURE';

export const PROGRESS_MEMBERS = 'PROGRESS_MEMBERS';
export const PROGRESS_MEMBERS_SUCCESS = 'PROGRESS_MEMBERS_SUCCESS';
export const PROGRESS_MEMBERS_FAILURE = 'PROGRESS_MEMBERS_FAILURE';

export const ITEMCODE = 'ITEMCODE';
export const ITEMCODE_SUCCESS = 'ITEMCODE_SUCCESS';
export const ITEMCODE_FAILURE = 'ITEMCODE_FAILURE';

export const G2BCODES = 'G2BCODES';
export const G2BCODES_SUCCESS = 'G2BCODES_SUCCESS';
export const G2BCODES_FAILURE = 'G2BCODES_FAILURE';

export const JCOMBASIC = 'JCOMBASIC';
export const JCOMBASIC_SUCCESS = 'JCOMBASIC_SUCCESS';
export const JCOMBASIC_FAILURE = 'JCOMBASIC_FAILURE';

export const ALLOW_AUTH = 'ALLOW_AUTH';
export const ALLOW_AUTH_SUCCESS = 'ALLOW_AUTH_SUCCESS';
export const ALLOW_AUTH_FAILURE = 'ALLOW_AUTH_FAILURE';

export const GROUPINIT = 'GROUPINIT';
export const GROUPINIT_SUCCESS = 'GROUPINIT_SUCCESS';
export const GROUPINIT_FAILURE = 'GROUPINIT_FAILURE';

export const PASSINIT = 'PASSINIT';
export const PASSINIT_SUCCESS = 'PASSINIT_SUCCESS';
export const PASSINIT_FAILURE = 'PASSINIT_FAILURE';

export const MEMO = 'MEMO';
export const MEMO_SUCCESS = 'MEMO_SUCCESS';
export const MEMO_FAILURE = 'MEMO_FAILURE';
