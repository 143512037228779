import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import { refreshToken } from '../auth.js';
import { store } from '../store/index';
import * as actionTypes from '@/store/action-types';
import { RoutesAdmin } from './routes.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: RoutesAdmin,
});

const checkAuth = (items, to) => {
  let path = to.path.split('/');
  let params_cnt = Object.keys(to.params).length;
  path.shift();
  if (path.length > params_cnt) {
    for (let i = 0; i < params_cnt; i++) {
      path.pop();
    }
  }
  if (!items) {
    return false;
  } else if (items[0] == 'administrator') {
    return true;
  } else if (path.length == 1 && items.indexOf(path[0]) !== -1) {
    return true;
  } else if (items.indexOf(path[0] + '.*') !== -1) {
    return true;
  } else if (path.length == 2 && items.indexOf(path[0] + '.' + path[1]) !== -1) {
    return true;
  } else if (items.indexOf(path[0] + '.' + path[1] + '.*') !== -1) {
    return true;
  } else if (path.length == 3 && items.indexOf(path[0] + '.' + path[1] + '.' + path[2]) !== -1) {
    return true;
  } else {
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  if (VueCookies.get('token') === null && VueCookies.get('refresh_token') !== null) {
    await refreshToken();
  }

  if (to.matched.some((record) => record.meta.unauthorized) || VueCookies.get('token')) {
    if (
      ['home', 'login', 'login2', 'officeanal', 'content-bid', 'content-suc', 'office', 'userdormant'].indexOf(
        to.name
      ) !== -1
    )
      return next();
    else if (store.state.auth.auth_items === null) {
      store.dispatch(actionTypes.ALLOW_AUTH).then(() => {
        const allow = checkAuth(store.state.auth.auth_items, to);
        if (to.matched.some((record) => record.meta.allow) || allow) {
          return next();
        } else {
          alert('접근권한이 없습니다.');
          return false;
        }
      });
      return false;
    } else {
      const allow = checkAuth(store.state.auth.auth_items, to);
      if (to.matched.some((record) => record.meta.allow) || allow) {
        return next();
      } else {
        alert('접근권한이 없습니다.');
        return false;
      }
    }
  }
  return next('/login');
});

export default router;
