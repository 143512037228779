import api from '@/api';
import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

const state = {
  form: {
    biddate_term: '0',
    bidtype: '',
    itemcode1: '',
    itemcode2: '',
    itemcode3: '',
    location: '',
    addr: '',
    officeno: '',
    officename: '',
    tel: '',
    prename: '',
    fax: '',
    folder_num: 0,
    org: '',
    charge_bidq: '',
    charge_info: '',
    counsel_kind: '',
    counsel_date: '',
    mng_num: '',
    sort: 'update_time',
    datetype: 'update_time',
    desc: 'desc',
  },
  items: [],
  loading: false,
  pagination: {
    page: 1,
    size: 40,
    totalElements: 0,
    pages: 1,
  },
  check_com: [],
};

const getters = {
  form: (state) => state.form,
  items: (state) => state.items,
  loading: (state) => state.loading,
  pagination: (state) => state.pagination,
  check_com: (state) => state.check_com,
};

const mutations = {
  [mutationTypes.FETCH]: (state) => {
    state.items = [];
    state.loading = true;
  },
  [mutationTypes.FETCH_SUCCESS]: (state, response) => {
    state.items = response.data;
    state.loading = false;
    state.pagination.totalElements = response.total;
  },
  [mutationTypes.FETCH_FAILURE]: (state) => {
    state.items = [];
    state.loading = false;
  },
};

const actions = {
  [actionTypes.FETCH]: async ({ commit }) => {
    commit(mutationTypes.FETCH);
    try {
      let form = state.form;
      form.page = state.pagination.page;
      form.size = state.pagination.size;
      const { data } = await api.fetchNewMarketingList({
        ...form,
      });
      commit(mutationTypes.FETCH_SUCCESS, data);
    } catch (e) {
      commit(mutationTypes.FETCH_FAILURE);
    }
  },
  setViewCom: ({ state }, session_com) => {
    state.check_com = session_com;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
