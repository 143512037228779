export const MANAGER_LOGIN = 'MANAGER_LOGIN';
export const MANAGER_SMS_LOGIN = 'MANAGER_SMS_LOGIN';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTO_LOGIN = 'AUTO_LOGIN';
export const AL_TOKEN = 'AL_TOKEN';

export const CREATE = 'CREATE';
export const DELETE = 'DELETE';
export const FETCH = 'FETCH';
export const GET = 'GET';
export const UPDATE = 'UPDATE';

export const MANAGERS = 'MANAGERS';
export const MEMBERS = 'MEMBERS';
export const PROGRESS_MEMBERS = 'PROGRESS_MEMBERS';
export const DORMANT = 'DORMANT';

export const ITEMCODE = 'ITEMCODE';
export const G2BCODES = 'G2BCODES';
export const JCOMBASIC = 'JCOMBASIC';

export const ALLOW_AUTH = 'ALLOW_AUTH';

export const GROUPINIT = 'GROUPINIT';
export const PASSINIT = 'PASSINIT';
export const MEMO = 'MEMO';
